.btnFater{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btnFater div{
    margin-right: 1rem;
    /* font-weight: ; */
    /* borderRadius: ; */
    /* flex: 1; */
}
.toLoad{
    min-height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}